import useSWR from 'swr'
import { tokenPythIdMapping } from './utils'
import priceStore from '@store/priceStore'
import { querySpreadReductionBps } from './usePythPricesFetcher'
import { useEffect, useMemo } from 'react'
import { isEmpty } from 'lodash'
import useActiveChainTokens from 'hooks/useActiveChainTokens'

export const usePythPrices = (chainId: number) => {
  const setSpreadReductionBps = priceStore(
    (store) => store.setSpreadReductionBps,
  )
  const spreadReductionBps = priceStore((store) => store.spreadReductionBps)
  const tokensList = useActiveChainTokens()

  const pythIdsForWS = useMemo(() => {
    const tokenPythIdList = tokensList
      ?.map((token) => token.pythId)
      .filter(Boolean)
    return (tokenPythIdList as string[]) || []
  }, [tokensList])

  async function getSpread() {
    const spread: Record<string, number> = {}
    for (let i = 0; i < pythIdsForWS.length; i++) {
      const pythId = pythIdsForWS[i]
      if (!pythId) {
        spread[pythId] = 0
      }
      const result = await querySpreadReductionBps(pythId)
      spread[pythId] = Number(result)
    }
    return spread
  }

  useEffect(() => {
    ;(async () => {
      if (!spreadReductionBps || isEmpty(spreadReductionBps)) {
        const _spread = await getSpread()
        setSpreadReductionBps(_spread)
      }
    })()
  }, [])

  const { data } = useSWR([
    'galeto-oracle-price',
    tokenPythIdMapping(chainId),
    chainId,
  ])

  return {
    pricesData: data?.priceInfo,
    updatedAt: data?.updatedAt,
  }
}
